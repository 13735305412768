import { FC, ReactNode, useLayoutEffect, useState } from 'react';
import amplitude from 'amplitude-js';

import { getUserAgentInfo, OS } from '../utils/userAgentInfo';
import { getLSValue } from '../utils/locale-storage';
import { USER_DATA } from '../constants/common';
import { UserLocalStorageData } from '../types/user';
import useQueryParams from '../hooks/use-query-params';
import { useAnalytic } from '../contexts';

type Props = {
  children: ReactNode;
};

const BotDetectionMiddleware: FC<Props> = ({ children }) => {
  const [showContent, setShowContent] = useState(false);
  const { getQueryParams } = useQueryParams();
  const { amplitudeInit } = useAnalytic();

  useLayoutEffect(() => {
    const userAgentInfo = getUserAgentInfo();
    const regex = new RegExp('bot|google|baidu|bing|msn|teoma|slurp|yandex', 'i');
    const isBot = regex.test(navigator.userAgent);

    if (
      amplitudeInit &&
      (userAgentInfo.os === OS.IosIphone || userAgentInfo.os === OS.Android) &&
      !isBot &&
      process.env.REACT_APP_BOT_REDIRECT_URL
    ) {
      const userLSData = getLSValue(USER_DATA, true) as UserLocalStorageData;
      const currentParams = getQueryParams();

      const validParams = Object.fromEntries(
        Object.entries(currentParams)
          .filter(([, value]) => value !== null)
          .map(([key, value]) => [key, String(value)]),
      );

      if (userLSData?.userId) {
        validParams.customer_id = userLSData.userId;
      }

      validParams.ampDeviceId = amplitude.getInstance().getDeviceId();
      validParams.ampSessionId = amplitude.getInstance().getSessionId().toString();

      const searchParams = new URLSearchParams(validParams).toString();

      const redirectUrl = `${process.env.REACT_APP_BOT_REDIRECT_URL}?${searchParams}`;

      window.location.replace(redirectUrl);
    } else {
      setShowContent(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amplitudeInit]);

  if (!showContent) {
    return null;
  }

  return <>{children}</>;
};

export { BotDetectionMiddleware };
