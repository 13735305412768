import React from 'react';

import appleStore from '../../assets/images/apple-store.svg';
import googlePlay from '../../assets/images/google-play.png';

import styles from './stores.module.scss';

type StoresProps = {
  onStoreClick?: (nameStore: string) => void;
};

const Stores = ({ onStoreClick }: StoresProps) => (
  <ul className={styles.stores}>
    <li className={styles.store}>
      <a
        className={styles.storeLink}
        href={process.env.REACT_APP_APPLE_STORE}
        onClick={() => onStoreClick && onStoreClick('appstore')}
      >
        <img src={appleStore} alt="apple store" />
      </a>
    </li>
    <li className={styles.store}>
      <a
        className={styles.storeLink}
        href={process.env.REACT_APP_GOOGLE_PLAY}
        onClick={() => onStoreClick && onStoreClick('googleplay')}
      >
        <img src={googlePlay} alt="google play" />
      </a>
    </li>
  </ul>
);

export { Stores };
