import React, { FC } from 'react';

import { Logo } from '../../features/quiz/components/logo';

import styles from './fullscreen-loader.module.scss';

const FullScreenLoader: FC = () => (
  <div className={styles.container}>
    <div className={styles.content}>
      <Logo />
      <div className={styles.loader}>
        <div className={styles.loader__spinner} />

        <div className={styles.loader__text}>loading</div>
      </div>
    </div>
  </div>
);

export { FullScreenLoader };
