import { USD_CURRENCY } from '../constants/currencies';

const DECIMAL_NUMBER = 100;

const getCurrency = (amount: string = '', currency: string = '') => {
  const resultAmount = amount ? Number(amount) / DECIMAL_NUMBER : '0';

  if (currency === USD_CURRENCY) {
    return `${resultAmount}$`;
  }

  return `${resultAmount}`;
};

export { getCurrency };
