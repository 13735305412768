import HttpService from '../http';
import { Status } from './types';

const API_URL = 'https://cloudrun-taplab-espv2-dev-ixo5ygzwga-ue.a.run.app/';

class UserDataApiService extends HttpService {
  constructor(baseUrl = '') {
    super(baseUrl);
  }

  async addUserIdData(data: { customer_id: string; adid: string }): Promise<Status> {
    try {
      return await this.post('payment/users?key=AIzaSyDhjCT71KP-357OpCrt-Q8hYPsFUdjBm60', {
        ...data,
        device: window.navigator.userAgent,
      });
    } catch (error: unknown) {
      console.error('Network error', error);

      throw error;
    }
  }

  async loadCustomerTracking(payload: Record<string, any>): Promise<{ status: string }> {
    try {
      return await this.post('conversions/pageload?key=AIzaSyDhjCT71KP-357OpCrt-Q8hYPsFUdjBm60', payload);
    } catch (error: unknown) {
      console.error('Network error', error);

      throw error;
    }
  }
}

export const userDataApiService = new UserDataApiService(API_URL);

export default userDataApiService;
