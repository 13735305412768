import React, { FC } from 'react';
import clsx from 'clsx';

import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { CreateSubscriptionActions } from '@paypal/paypal-js/types/components/buttons';

import styles from './paypal-button.module.scss';

type PaypalButtonProps = {
  clientId: string;
  paypalPlanKey: string;
  successCallback: () => void;
  errorCallback: (error: unknown) => void;
  clickCallback: () => void;
  className?: string;
  height?: number;
};

const DEFAULT_HEIGHT = 55;

const PaypalButton: FC<PaypalButtonProps> = ({
  height = DEFAULT_HEIGHT,
  clientId,
  paypalPlanKey,
  successCallback,
  errorCallback,
  clickCallback,
  className,
}) => (
  <PayPalScriptProvider
    options={{
      intent: 'subscription',
      vault: true,
      disableFunding: 'card',
      clientId,
    }}
  >
    <PayPalButtons
      className={clsx(styles.paypalContainerButton, className)}
      createSubscription={async (data: Record<string, unknown>, actions: CreateSubscriptionActions) =>
        actions.subscription.create({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          plan_id: paypalPlanKey,
        })
      }
      onClick={clickCallback}
      onApprove={async () => successCallback()}
      onError={(error: unknown) => errorCallback(error)}
      style={{ height, shape: 'rect', color: 'gold', layout: 'vertical', label: 'paypal' }}
    />
  </PayPalScriptProvider>
);

export { PaypalButton };
