import { Plan } from '../types/plan';

const TRIAL_PLANS: Plan[] = [
  {
    id: 'trial-plan-1',
    isMostPopular: true,
    introPlanKey: 'perky;USD;399;;day',
    mainPlanKey: 'premium_perky;USD;2099;1;quarter',
    plan: '1 quarter',
    planPeriod: 'quarter',
    trialPrice: '399',
    price: '2099',
    pricePerWeek: '174',
    currency: 'USD',
    currencySymbol: '$',
    paypalPlanKey: 'P-9P4683516R1155529MZVWXXA',
    trialDuration: 3,
  },
  {
    id: 'trial-plan-0',
    introPlanKey: 'perky;USD;399;;day',
    mainPlanKey: 'premium_perky;USD;799;1;week',
    plan: '1 week',
    planPeriod: 'week',
    trialPrice: '399',
    price: '799',
    pricePerWeek: '799',
    currency: 'USD',
    currencySymbol: '$',
    paypalPlanKey: 'P-58H60482YC618431SMZVROSQ',
    trialDuration: 3,
  },
];

export { TRIAL_PLANS };
