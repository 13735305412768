import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAnalytic } from '../../contexts';
import { ROUTES } from '../../constants/routes';
import { trial_bg } from '../../survey-data/images';
import { Button, BUTTON_VARIANTS, SecureInfo, Title } from '../../components';
import { TRIAL_PLANS } from '../../constants/plans';
import { getCurrency } from '../../utils/currency';
import { MONEY_BACK_POLICY_LINK, USER_DATA } from '../../constants/common';
import { getLSValue, setLSValue } from '../../utils/locale-storage';
import { UserLocalStorageData } from '../../types/user';

import styles from './trial.module.scss';

const DAYS_IN_WEEK = 7;
const CENTS_IN_DOLLAR = 100;

const Trial = () => {
  const { sendEvent } = useAnalytic();
  const navigate = useNavigate();
  const [planId, setPlanId] = useState<string | null>(TRIAL_PLANS[0].id);

  const plan = TRIAL_PLANS.find((trialPlan) => planId === trialPlan.id);

  const handleChangePlan = (event: ChangeEvent<HTMLInputElement>) => {
    setPlanId(event.target.value);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    const userData = getLSValue(USER_DATA, true) as UserLocalStorageData;

    setLSValue(USER_DATA, { ...userData, plan });

    sendEvent('PerkyOnboardingTrialClicked', {
      plan: plan?.plan,
      trialPrice: plan?.trialPrice,
      price: plan?.price,
      currency: plan?.currency,
    });
    navigate(ROUTES.PAYWALL_PAGE);
  };

  useEffect(() => {
    sendEvent('PerkyOnboardingTrialViewed');
  }, [sendEvent]);

  return (
    <div className={styles.root}>
      <div className={styles.bg}>
        <img src={trial_bg} alt="trial background" />
      </div>
      <div className={styles.container}>
        <Title
          title="Start your"
          className={styles.title}
          secondPartTitle={`${plan?.trialDuration || '7'} day trial`}
          isSmall
        />
        <div className={styles.subTitle}>
          Create an <span className={styles.bold}>unlimited number</span> of images at high speed and with the best
          quality
        </div>
        <form onSubmit={handleSubmit}>
          {TRIAL_PLANS.map((trialPLan) => (
            <div key={trialPLan.id} className={styles.formField}>
              <input
                className={styles.input}
                id={trialPLan.id}
                type="radio"
                name={trialPLan.id}
                value={trialPLan.id}
                checked={trialPLan.id === planId}
                onChange={handleChangePlan}
              />
              <label className={styles.label} htmlFor={trialPLan.id}>
                {trialPLan.isMostPopular && <div className={styles.popular}>most popular</div>}
                <div className={styles.amount}>
                  {trialPLan.plan}
                  <div className={styles.amountDescription}>
                    {getCurrency(trialPLan.price, trialPLan.currency)}/{trialPLan.plan}
                  </div>
                </div>
                <div className={styles.price}>
                  <div className={styles.priceWrapper}>
                    {getCurrency(trialPLan.trialPrice, trialPLan.currency)}
                    <div className={styles.priceDescription}>for the first {trialPLan?.trialDuration || '7'} days</div>
                  </div>
                </div>
              </label>
            </div>
          ))}

          <div className={styles.plans_addition_info}>
            <span>Money back guarantee</span>
            <span className={styles.tax_info}>Tax included</span>
          </div>

          <Button variant={BUTTON_VARIANTS.SECONDARY} className={styles.submitButton}>
            Subscribe
          </Button>
        </form>
        <div className={styles.info}>
          By continuing, you agree that if you don’t cancel at least 24 hours before the end of{' '}
          <span className={styles.bold}>
            the {plan?.trialDuration || DAYS_IN_WEEK}-days trial for {plan?.currencySymbol || '$'}
            {Number(plan?.trialPrice || '99') / CENTS_IN_DOLLAR}
          </span>
          , you will automatically be charged {plan?.currencySymbol || '$'}
          {Number(plan?.price) / CENTS_IN_DOLLAR} every {plan?.planPeriod || 'month'} until you cancel subscription.
          Learn more about cancellation and refund policy in our{' '}
          <a href={MONEY_BACK_POLICY_LINK} className={styles.link}>
            Money Back Policy
          </a>
        </div>
        <SecureInfo />
      </div>
    </div>
  );
};

export { Trial };
