/* eslint-disable @typescript-eslint/no-explicit-any */

export default class HttpService {
  private baseUrl: string;

  constructor(baseURL = '') {
    this.baseUrl = baseURL;
  }

  async get<T = any>(url: string, config?: RequestInit): Promise<T> {
    return this.request<T>(url, {
      method: 'GET',
      ...config,
    });
  }

  async post<T = any>(url: string, data?: any, config?: RequestInit): Promise<T> {
    return this.request<T>(url, {
      method: 'POST',
      body: data ? JSON.stringify(data) : undefined,
      ...config,
    });
  }

  async patch<T = any>(url: string, data?: any, config?: RequestInit): Promise<T> {
    return this.request<T>(url, {
      method: 'PATCH',
      body: data ? JSON.stringify(data) : undefined,
      ...config,
    });
  }

  async put<T = any>(url: string, data?: any, config?: RequestInit): Promise<T> {
    return this.request<T>(url, {
      method: 'PUT',
      body: data ? JSON.stringify(data) : undefined,
      ...config,
    });
  }

  async delete<T = any>(url: string, data?: any, config?: RequestInit): Promise<T> {
    return this.request<T>(url, {
      method: 'DELETE',
      body: data ? JSON.stringify(data) : undefined,
      ...config,
    });
  }

  private async request<T = any>(url: string, config?: RequestInit): Promise<T> {
    const response = await fetch(`${this.baseUrl}${url}`, {
      headers: {
        ['Content-Type']: 'application/json',
        ...config?.headers,
      },
      ...config,
    });

    if (!response.ok) {
      throw await response.json();
    }

    return response.json() as Promise<T>;
  }
}
