import React, { ChangeEvent, FC, FormEvent, useState } from 'react';

import Adjust from '@adjustcom/adjust-web-sdk';

import { Button, Input, Modal, Title } from '../../../../components';
import lockIcon from '../../assets/images/lock_icon.svg';
import { getLSValue, setLSValue } from '../../../../utils/locale-storage';
import { USER_DATA } from '../../../../constants/common';
import { UserLocalStorageData } from '../../../../types/user';
import { webApiService } from '../../../../services/web-api';
import { TRIAL_PLANS } from '../../../../constants/plans';
import userDataApiService from '../../../../services/web-api/user-data-api';

import styles from './email-modal.module.scss';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

type Props = {
  onComplete?: (email: string) => void;
};

const EmailModal: FC<Props> = ({ onComplete }) => {
  const userLSData = getLSValue(USER_DATA, true) as UserLocalStorageData;

  const [email, setEmail] = useState(userLSData?.email || '');
  const [valid, setValid] = useState(true);
  const [disabled, setDisabled] = useState(!emailRegex.test(userLSData?.email || ''));
  const [loading, setLoading] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const isValid = emailRegex.test(value);

    setValid(isValid);
    setDisabled(!isValid);
    setEmail(value);
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    setDisabled(true);
    setLoading(true);

    try {
      const webUuid = Adjust.getWebUUID() || '';

      await userDataApiService.addUserIdData({ customer_id: userLSData.userId, adid: webUuid });
      await webApiService.addEmailToCustomer(userLSData.userId, email);

      setLSValue(USER_DATA, { ...userLSData, email, plan: TRIAL_PLANS[0] });

      onComplete?.(email);
    } catch (error: unknown) {
      console.error(error);
      setDisabled(false);
      setLoading(false);
    }
  };

  return (
    <Modal isBlurOverlay>
      <div className={styles.modal_content}>
        <div className={styles.header}>
          <p className={styles.subtitle}>
            Powered by <span className={styles.blue_text}>Stable Diffusion</span>
          </p>
          <Title title="Enter" secondPartTitle="email" className={styles.title} />
          <p className={styles.description}>Enter email to start creating a high-quality AI images right now</p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className={styles.input_container}>
            <Input
              name="email"
              value={email}
              onChange={handleChange}
              className={styles.input}
              isError={!valid}
              placeholder="email@mail.com"
            />
            <div className={styles.input_description}>
              <img src={lockIcon} alt="lock" />
              <p>We respect your privacy and never share your personal data with any third parties.</p>
            </div>
          </div>
          <Button disabled={disabled || loading} type="submit" variant="secondary">
            Continue
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export { EmailModal };
