/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState } from 'react';
import clsx from 'clsx';
import closeIcon from 'src/assets/images/close-icon.svg';
import cardVisa from 'src/assets/images/card-visa.png';
import cardMastercard from 'src/assets/images/card-mastercard.png';
import cardMaestro from 'src/assets/images/card-maestro.png';
import cardDiscover from 'src/assets/images/card-discover.png';
import cardAmex from 'src/assets/images/card-amex.png';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { Loading } from '../../../../components';
import { StripeForm } from '../stripe-form';

import styles from './payment-modal.module.scss';

type PaymentModalProps = {
  stripePublishableKey: string;
  purchaseConfirm: (purchaseId: string) => void;
  billingId: string;
  onClose: () => void;
};

const PaymentModal = ({ stripePublishableKey, purchaseConfirm, billingId, onClose }: PaymentModalProps) => {
  const [loading, setLoaded] = useState(true);

  return (
    <div className={styles.paymentModal}>
      <button className={styles.closeButton} onClick={onClose}>
        <img src={closeIcon} alt="close button" />
      </button>
      <h2 className={styles.title}>Credit / Debit Card</h2>
      <ul className={styles.cards}>
        <li className={styles.card}>
          <img src={cardVisa} alt="card visa" className={clsx(styles.cardImage, styles.visa)} />
        </li>
        <li className={styles.card}>
          <img src={cardMastercard} alt="card mastercard" className={clsx(styles.cardImage, styles.mastercard)} />
        </li>
        <li>
          <img src={cardAmex} alt="card amex" className={clsx(styles.cardImage, styles.amex)} />
        </li>
        <li className={styles.card}>
          <img src={cardMaestro} alt="card maestro" className={clsx(styles.cardImage, styles.maestro)} />
        </li>
        <li className={styles.card}>
          <img src={cardDiscover} alt="card discover" className={clsx(styles.cardImage, styles.discover)} />
        </li>
      </ul>
      <div className={styles.divider} />
      {loading && (
        <div className={styles.loading}>
          <Loading isDark />
        </div>
      )}
      {stripePublishableKey && (
        <div className={clsx({ [styles.hidden]: loading })}>
          <Elements
            stripe={loadStripe(stripePublishableKey)}
            options={{
              loader: 'always',
              appearance: {
                disableAnimations: true,
                variables: {
                  colorDanger: '#FF5151',
                },
                rules: {
                  '.Error': {
                    fontSize: '10px',
                  },
                  '.TermsText': {
                    fontSize: '0',
                  },
                  '.Input': {
                    padding: '15px 12px',
                    borderRadius: '6px',
                    color: '#1B1A1F',
                  },
                  '.Input:placeholder': {
                    color: '#1B1A1F',
                  },
                  '.Input:focus': {
                    boxShadow: 'none',
                    borderColor: '#D8D8D8',
                  },
                  '.Label': {
                    fontSize: '0',
                  },
                },
              },
              fonts: [{ cssSrc: 'https://fonts.google.com/specimen/Space+Grotesk' }],
            }}
          >
            <StripeForm setLoaded={setLoaded} billingId={billingId} purchaseConfirm={purchaseConfirm} />
          </Elements>
        </div>
      )}
    </div>
  );
};

export { PaymentModal };
