import React, { useEffect } from 'react';
import { Button, Title, Stores, BUTTON_VARIANTS } from 'src/components';
import aiIcon from 'src/assets/images/ai-icon.svg';
import { useNavigate } from 'react-router-dom';

import { useGrowthBook } from '@growthbook/growthbook-react';

import { useAnalytic } from '../../contexts';
import { ROUTES } from '../../constants/routes';
import { P_TRIAL_PAYMENT_LAYOUT } from '../../constants/growthbook-features';

import styles from './failed.module.scss';

const Failed = () => {
  const growthBook = useGrowthBook();
  const { sendEvent } = useAnalytic();
  const navigate = useNavigate();

  useEffect(() => {
    sendEvent('PerkyOnboardingFailedViewed');
  }, [sendEvent]);

  const handleStoreClick = (name: string) => {
    sendEvent('PerkyOnboardingFailedDownloadClicked', name);
  };

  const handleClickTryButton = () => {
    sendEvent('PerkyOnboardingFailedClicked');

    if (!growthBook?.isOn(P_TRIAL_PAYMENT_LAYOUT)) {
      navigate(ROUTES.TRIAL_PAGE);
    } else {
      navigate(ROUTES.PAYWALL_PAGE);
    }
  };

  return (
    <div className={styles.root}>
      <img className={styles.logo} src={aiIcon} alt="ai icon" />
      <Title title="Payment" secondPartTitle="failed" className={styles.title} />
      <Button className={styles.tryAgainButton} variant={BUTTON_VARIANTS.SECONDARY} onClick={handleClickTryButton}>
        Try again
      </Button>
      <Title title="Or continue" secondPartTitle="with the app" className={styles.title} isSmall />
      <Stores onStoreClick={handleStoreClick} />
    </div>
  );
};

export { Failed };
