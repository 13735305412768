import React from 'react';
import clsx from 'clsx';

import styles from './info.module.scss';

const Info = ({ className }: { className?: string }) => (
  <div className={clsx(styles.info, className)}>
    You will be charged only <span className={styles.bold}>0,99$ for your 7-day trial</span>.<br /> We’ll{' '}
    <span className={styles.bold}>email you a reminder</span> before your trial period ends.
    <br /> Cancel anytime.
  </div>
);

export { Info };
