import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { Button, BUTTON_VARIANTS, Loading } from '../../../../components';
import { ROUTES } from '../../../../constants/routes';
import { webApiService } from '../../../../services/web-api';
import { Info } from '../info';
import { getLSValue } from '../../../../utils/locale-storage';
import { USER_DATA } from '../../../../constants/common';
import { UserLocalStorageData } from '../../../../types/user';
import { useAnalytic } from '../../../../contexts';

import styles from './stripe-form.module.scss';

type StripeFormProps = {
  billingId: string;
  purchaseConfirm: (purchaseId: string) => void;
  setLoaded: (state: boolean) => void;
};

const StripeForm = ({ setLoaded, billingId, purchaseConfirm }: StripeFormProps) => {
  const currentUserData = useMemo(() => getLSValue(USER_DATA, true) as UserLocalStorageData, []);
  const navigate = useNavigate();
  const elements = useElements();
  const stripe = useStripe();
  const { sendEvent } = useAnalytic();
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (elements) {
      const element = elements.getElement('payment');
      element?.on('ready', () => {
        setLoaded(false);
      });
    }
  }, [elements, setLoaded]);

  const handleSubmit = async (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setSubmitting(true);

    await elements.submit();

    let clientSecret = '';
    let purchaseId = '';
    try {
      const data = await webApiService.purchaseSubscriptions({
        app: process.env.REACT_APP_NAME_APP || '',
        billing_id: billingId || '',
        billing_type: 'stripe',
        customer_id: currentUserData.userId,
        paypal: {
          app: process.env.REACT_APP_NAME_APP || '',
          customer_id: currentUserData.userId,
          plan: currentUserData.plan?.introPlanKey || '',
        },
        stripe: {
          customer_id: currentUserData.userId,
          intro_plan_key: currentUserData.plan?.introPlanKey || '',
          main_plan_key: currentUserData.plan?.mainPlanKey || '',
          trial_duration: currentUserData.plan?.trialDuration || 0,
        },
      });

      clientSecret = data.stripe.client_secret;
      purchaseId = data.purchase_id;
    } catch (error: unknown) {
      console.error(error);
    }

    const result = await stripe.confirmPayment({
      elements,
      clientSecret: clientSecret,
      redirect: 'if_required',
      confirmParams: {
        return_url: `${window.location.origin}/${ROUTES.SUCCESS_PAGE}`,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        payment_method_data: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          billing_details: {
            name: '',
            address: {
              country: 'GB',
            },
          },
        },
      },
    });

    if (result.error?.type && result.error?.type !== 'validation_error') {
      sendEvent('PerkyOnboardingPaywallSubscriptionPurchased', {
        status: 'error',
        paymentMethod: result.error?.payment_method,
        ...currentUserData,
      });

      navigate(ROUTES.FAILED_PAGE);
    }

    if (result.error?.type && result.error?.type === 'validation_error') {
      setSubmitting(false);
    }

    if (result?.paymentIntent?.status === 'succeeded') {
      sendEvent('PerkyOnboardingPaywallSubscriptionPurchased', {
        status: 'success',
        paymentMethod: result.paymentIntent?.payment_method,
        ...currentUserData,
      });

      purchaseConfirm(purchaseId);
      navigate(ROUTES.SUCCESS_PAGE);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement
        options={{
          fields: {
            billingDetails: {
              address: {
                country: 'never',
              },
            },
          },
        }}
      />
      <Info />
      <Button disabled={!stripe || submitting} variant={BUTTON_VARIANTS.SECONDARY}>
        <div className={styles.loadingText}>
          {submitting && <Loading className={styles.loading} />} Start 7 day trial
        </div>
      </Button>
    </form>
  );
};

export { StripeForm };
