import { createContext } from 'react';

type AnalyticContextProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendEvent: (name: string, params?: any) => void;
  sendAmplitudeEvent: (event: { name: string; props?: Record<string, any> }) => void;
  setUserProperties: (userProperties: Record<string, any>) => void;
  amplitudeInit: boolean;
};

const AnalyticContext = createContext<AnalyticContextProps>({
  sendEvent: () => undefined,
  sendAmplitudeEvent: () => undefined,
  setUserProperties: () => undefined,
  amplitudeInit: false,
});

export { type AnalyticContextProps, AnalyticContext };
