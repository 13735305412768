import { FC, JSX } from 'react';

import styles from './slide.module.scss';

type Props = {
  title: JSX.Element;
  description: JSX.Element;
  img: string;
};

const Slide: FC<Props> = ({ title, description, img }) => (
  <div className={styles.container}>
    <div className={styles.title}>{title}</div>
    <div className={styles.img_container}>
      <img src={img} alt="" className={styles.img} />
    </div>
    <div className={styles.description}>{description}</div>
  </div>
);

export { Slide };
