import React, { useEffect } from 'react';
import { CopyButton, Stores, Title } from 'src/components';
import aiIcon from 'src/assets/images/ai-icon.svg';
import checkIcon from 'src/assets/images/success/check-icon.svg';

import { useAnalytic } from '../../contexts';
import { getLSValue } from '../../utils/locale-storage';
import { USER_DATA } from '../../constants/common';
import { UserLocalStorageData } from '../../types/user';

import styles from './success.module.scss';

const Success = () => {
  const { sendEvent } = useAnalytic();

  useEffect(() => {
    sendEvent('PerkyOnboardingSuccessViewed');
  }, [sendEvent]);

  const handleStoreClick = (name: string) => {
    sendEvent('PerkyOnboardingSuccessDownloadClicked', name);
  };

  const handleCopyButtonClick = () => {
    sendEvent('PerkyOnboardingSuccessCopyEmailClicked');
  };

  return (
    <div className={styles.root}>
      <img className={styles.logo} src={aiIcon} alt="ai icon" />
      <Title title="Payment" secondPartTitle="success" className={styles.title} />
      <img className={styles.checkIcon} src={checkIcon} alt="check-icon" />
      <Title className={styles.subTitle} tag="h2" title="To continue" secondPartTitle="download the app" isSmall />
      <Stores onStoreClick={handleStoreClick} />
      <div className={styles.text}>and login with the email</div>
      <CopyButton
        onClick={handleCopyButtonClick}
        className={styles.copyButton}
        textToCopy={(getLSValue(USER_DATA, true) as UserLocalStorageData)?.email || ''}
      />
      <div className={styles.text}>to activate your unlimited access</div>
    </div>
  );
};

export { Success };
