import aiLogo from '../../assets/images/ai-logo.svg';

import styles from './logo.module.scss';

const Logo = () => (
  <div className={styles.logo}>
    <img src={aiLogo} alt="logo" className={styles.logo_icon} />
    <span className={styles.logo_label}>Imagin8</span>
  </div>
);

export { Logo };
