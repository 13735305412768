import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { getLSValue, setLSValue } from '../../utils/locale-storage';
import { QUIZ_FINISHED, SURVEY_STEPS_DATA, USER_DATA } from '../../constants/common';
import { ROUTES } from '../../constants/routes';
import { UserLocalStorageData } from '../../types/user';

const ProtectedRoute = ({ checkEmail }: { checkEmail?: boolean }) => {
  const isQuizFinished = getLSValue(QUIZ_FINISHED, true) as boolean;
  const userData = (getLSValue(USER_DATA, true) as UserLocalStorageData) || {};

  if (!isQuizFinished) {
    setLSValue(SURVEY_STEPS_DATA, []);

    return <Navigate to={ROUTES.INDEX_PAGE} replace />;
  }

  if (checkEmail && !userData.email) {
    return <Navigate to={ROUTES.INDEX_PAGE} replace />;
  }

  return <Outlet />;
};

export { ProtectedRoute };
