/* eslint-disable @typescript-eslint/naming-convention */
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loading, PaypalButton, Title } from 'src/components';
import clsx from 'clsx';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { ConfigResponse, webApiService } from '../../../services/web-api';
import { getLSValue } from '../../../utils/locale-storage';
import { USER_DATA } from '../../../constants/common';
import { useAnalytic } from '../../../contexts';
import { UserLocalStorageData } from '../../../types/user';
// eslint-disable-next-line import/order
import { ROUTES } from '../../../constants/routes';
// eslint-disable-next-line import/order
import { StripeForm } from './stripe-form';
import { getCurrency } from '../../../utils/currency';

import styles from './variant-a.module.scss';

type VariantAProps = {
  paymentConfig?: ConfigResponse;
  purchaseConfirm: (purchaseId: string) => void;
};

const VariantA = ({ purchaseConfirm, paymentConfig }: VariantAProps) => {
  const navigate = useNavigate();
  const currentUserData = useMemo(() => getLSValue(USER_DATA, true) as UserLocalStorageData, []);
  const { sendEvent } = useAnalytic();
  const [loading, setLoading] = useState(true);

  const handlePaypalClick = () => {
    sendEvent('PerkyOnboardingPaywallPaymentAttempt', {
      paymentMethod: 'paypal',
      ...currentUserData,
    });
  };

  const handlePaypalSuccess = async () => {
    sendEvent('PerkyOnboardingPaywallSubscriptionPurchased', {
      status: 'success',
      paymentMethod: 'paypal',
      ...currentUserData,
    });

    let purchaseId = '';
    try {
      const data = await webApiService.purchaseSubscriptions({
        app: process.env.REACT_APP_NAME_APP || '',
        billing_id: paymentConfig?.checkout.paypal.account_id || '',
        billing_type: 'paypal',
        customer_id: currentUserData.userId,
        paypal: {
          app: process.env.REACT_APP_NAME_APP || '',
          customer_id: currentUserData.userId,
          plan: currentUserData.plan?.introPlanKey || '',
        },
        stripe: {
          customer_id: currentUserData.userId,
          intro_plan_key: currentUserData.plan?.introPlanKey || '',
          main_plan_key: currentUserData.plan?.mainPlanKey || '',
          trial_duration: currentUserData.plan?.trialDuration || 0,
        },
      });

      purchaseId = data.purchase_id;
    } catch (error: unknown) {
      console.error(error);
    }

    purchaseConfirm(purchaseId);

    navigate(ROUTES.SUCCESS_PAGE);
  };

  const handlePaypalFailed = (error: unknown) => {
    console.error(error);
    sendEvent('PerkyOnboardingPaywallSubscriptionPurchased', {
      status: 'error',
      paymentMethod: 'paypal',
      ...currentUserData,
    });

    navigate(ROUTES.FAILED_PAGE);
  };

  return (
    <div className={styles.root}>
      <Title className={styles.title} title="Select a secure" secondPartTitle="payment method" isSmall />

      {paymentConfig && (
        <PaypalButton
          clickCallback={handlePaypalClick}
          paypalPlanKey={currentUserData.plan?.paypalPlanKey || ''}
          successCallback={handlePaypalSuccess}
          errorCallback={handlePaypalFailed}
          clientId={paymentConfig.checkout.paypal.client_id}
        />
      )}
      {loading && (
        <div className={styles.loading}>
          <Loading isDark />
        </div>
      )}
      {paymentConfig && (
        <div className={clsx({ [styles.hidden]: false })}>
          <Elements
            stripe={loadStripe(paymentConfig.checkout.stripe.publishable_key)}
            options={{
              mode: 'subscription',
              amount: Number(currentUserData?.plan?.trialPrice || currentUserData?.plan?.price),
              currency: currentUserData.plan?.currency?.toLowerCase() || 'usd',
            }}
          >
            <StripeForm
              setLoading={setLoading}
              total={getCurrency(currentUserData?.plan?.trialPrice, currentUserData?.plan?.currency)}
              purchaseConfirm={purchaseConfirm}
              billingId={paymentConfig.checkout.stripe.account_id}
            />
          </Elements>
        </div>
      )}
    </div>
  );
};

export { VariantA };
