const ROUTES = {
  INDEX_PAGE: '/',
  WELCOME: '/welcome',
  ABOUT: '/about',
  CHARACTER_PAGE: '/character',
  SEX_PAGE: '/sex',
  // Man
  MAN_AGE_PAGE: '/man-age',
  MAN_ETHNICITY_PAGE: '/man-ethnicity',
  MAN_HAIR_PAGE: '/man-hair',
  MAN_BODY_PAGE: '/man-body',
  MAN_CHARMS_PAGE: '/man-charms',
  MAN_CLOTHES_PAGE: '/man-clothes',
  // woman
  WOMAN_AGE_PAGE: '/woman-age',
  WOMAN_ETHNICITY_PAGE: '/woman-ethnicity',
  WOMAN_HAIR_PAGE: '/woman-hair',
  WOMAN_CHARMS_PAGE: '/woman-charms',
  WOMAN_BODY_PAGE: '/woman-body',
  WOMAN_CLOTHES_PAGE: '/woman-clothes',

  GENERATE_PAGE: '/generate',
  EMAIL_PAGE: '/email',
  TRIAL_PAGE: '/trial',
  PAYWALL_PAGE: '/paywall',
  SUCCESS_PAGE: '/success',
  FAILED_PAGE: '/failed',
};

export { ROUTES };
