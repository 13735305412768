import React, { ReactNode, ButtonHTMLAttributes } from 'react';
import clsx from 'clsx';

import styles from './button.module.scss';

const BUTTON_VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

type ButtonVariant = (typeof BUTTON_VARIANTS)[keyof typeof BUTTON_VARIANTS];

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string;
  children: ReactNode;
  variant?: ButtonVariant;
  icon?: ReactNode;
  isSmall?: boolean;
};

const Button = ({ isSmall, icon, className, children, variant = BUTTON_VARIANTS.PRIMARY, ...props }: ButtonProps) => (
  <button className={clsx(styles.button, styles[variant], { [styles.small]: isSmall }, className)} {...props}>
    {children}
    {Boolean(icon) && <div className={styles.icon}>{icon}</div>}
  </button>
);

export { Button, type ButtonProps, BUTTON_VARIANTS, type ButtonVariant };
